$color_white: #fff;
$color_pale_while: #f7f7f7;
$color_black: #000;
$color_ebony: #111526;
$color_jungle_green: #29a2a7;
$color_dove_grey: #707070;
$color_cod_grey: #141414;
$color_gun_powder: #43425d;
$color_light_grey: rgba(0, 0, 0, 0.24);
$color_concrete: #f3f3f3;
$color_transparent: #00000000;
$color_cornflower_blue: #30afb6;
$color_sea_blue: #e1eff2;
$color_seashell: #f1f1f1;
$color_outrageous_orange: #ff6444;
$color_orangey_yellow: #fdaf20;
$color_butter_cup: #f0ae1b;
$color_light_blue_grey: #d2ebf1;
$color_grid_light_blue_one: #f7fbfd;
$color_grid_light_blue_two: #d1ebf1;
$color_booger: #8cc63f;
$color_pinkish_red: #ed1c24;
$color_alto_grey: #d7d7d7;
$color_imperial_blue: #2f354c;
$color_duck_egg: #d2e7e5;
$color_summer_green: #93b7b5;
$color_faded_jade_with_opacity: #41817d8c;
$color_faded_jade: #41817d;
$color_powder_blue_with_opacity: #bce0ea24;

/* border in navbar item */
/* $color_cornflower_blue: #30afb6; --> $color_imperial_blue: #2f354c; */

/* dropdown selected item feColor and hover color
$color_light_blue_grey: #d2ebf1 -->  #93B7B5 */

/* hover color : #41817D */

/* change loader color : to #2f354c; */

/* login button shadow color */

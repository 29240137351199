$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$screen-xxl-min: 1440px;

// Small devices
@mixin large-phone-mode {
  @media only screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin tablet-mode {
  @media only screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin large-tablet-mode {
  @media only screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin desktop-mode {
  @media only screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
//Extra Extra large devices
@mixin large-desktop-mode {
  @media only screen and (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
